<template>
  <div id="app">
    <img class="img" src="./assets/1.png" />
    <div class="icp">
      <a href="https://beian.miit.gov.cn/#/Integrated/index"
        >浙ICP备2024109524号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.icp {
  margin-top: -30px;
  a {
    color: #fff;
    text-decoration: none;
    font-size: 11px;
  }
}
.img {
  width: 60%;
  object-fit: contain;
}
</style>
